import { store } from '../app';
import { updateInvalidations } from './reducers/invalidations';

const invalidateTags = (tags) => (result, error) => {
  if (!error) {
    store.dispatch(updateInvalidations(tags));
    return tags;
  }
  return [];
};

export default invalidateTags;

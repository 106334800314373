import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import invalidateTags from '../invalidateTags';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';



const initialState = {
  currentUser: {
    permissions: [],
  },
  organization_uuid: '',
  lender_uuid: '',
  isLoggedIn: false,
};

// Separate auth reducer
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (auth, action) => {
      ls('user', action.payload);
      auth.currentUser = action.payload;
      auth.organization_uuid = action.payload.organization_uuid;
      auth.lender_uuid = action.payload.lender_uuid;
      auth.isLoggedIn = true;
    },
    logout: () => { }, // triggers appReducer conditional, resets all state to initialState

    updateCurrentUser: (auth, action) => {
      const payload = {
        ...auth.currentUser,
        ...action.payload,
      };
      ls('user', payload);
      auth.currentUser = payload;
    },

    setOrganizationUUID: (auth, action) => {
      auth.organization_uuid = action.payload;
    },

    // getSessionsLender: (auth, action) => {
    //   auth.journal_logins_by_uuid = action.payload;
    // },
  },
});

export const {
  login,
  logout,

  updateCurrentUser,

  setOrganizationUUID,
  getJournalLoginsSelf,
} = slice.actions;

export default slice;



// Logout with cleanup
export const startLogout = () => async (dispatch) => {
  ls.remove('user');
  dispatch(apiSlice.util.resetApiState()); // Clear RTK query cache
  dispatch(logout()); // triggers appReducer conditional, resets all state to initialState
};



const tags = {
  sessions: 'sessions',
  users: 'users',
};

const authApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({
      // Login
      login: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/login/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            const { lender, refresh_tos, pw_rotation, pw_token } = response.data;

            if (pw_rotation) {
              history.push(`/reset-password/${payload.organization_uuid}/${pw_token}`, { required: true });
              return { data: response.data };
            }

            ls.remove('email');
            api.dispatch(login({ ...lender, refresh_tos }));
            history.push('/dashboard');
            return { data: response.data };
          } catch (error) {
            if (error.response?.status === 409) {
              ls('email', payload.data.email);
              history.push(`/validate/session/${payload.organization_uuid}`);
            } else if (error.response?.status === 423) {
              toast.error('Account locked due to consecutive login failures. Please try again later.');
            } else {
              toast.error('It looks like something went wrong.');
            }
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      // Logout
      logout: build.mutation({
        queryFn: async (payload, api) => {
          const organization_uuid = ls('organizationBranding')?.organization_uuid;
          try {
            const response = await axios({
              url: `${baseURL}/logout`,
              method: 'GET',
            });
            api.dispatch(startLogout());
            history.push(organization_uuid ? `/login/${organization_uuid}` : '/');
            return { data: response.data };
          } catch (error) {
            api.dispatch(startLogout());
            history.push(organization_uuid ? `/login/${organization_uuid}` : '/');
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get org registration data
      getRegistrationData: build.query({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/register/${payload.organization_uuid}/${payload.token_uuid}`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Register
      register: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/register/${payload.organization_uuid}/${payload.token_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            const { lender } = response.data;
            api.dispatch(login({ ...lender, refresh_tos: false })); // Add user data to redux store
            history.push('/dashboard');
            return { data: response.data };
          } catch (error) {
            if (error.response?.status === 409) {
              ls('email', payload.email);
              history.push(`/validate/session/${payload.organization_uuid}`);
              toast.success('Check your email for your code.');
            } else {
              toast.error('It looks like something went wrong. Please try again.');
            }
            return { error: error.message };
          }
        },
      }),



      // Send Forgot Password
      sendForgotPassword: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-email/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Validate MFA (Multi-Factor Authentication)
      validateMFA: build.mutation({
        queryFn: async (payload, api) => {
          try {
            if (!payload.data.email) {
              toast.error('No valid email found');
              return { data: 'No email' };
            }
            const response = await axios({
              url: `${baseURL}/validate/session/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            if (response.data.lender) {
              const { lender, refresh_tos } = response.data;
              ls.remove('email');
              api.dispatch(login({ ...lender, refresh_tos })); // Add user data to redux store
              history.push('/dashboard');
            } else {
              toast.warn('Something went wrong, Resend a new code');
            }
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Resend MFA Code
      resendMFACode: build.mutation({
        queryFn: async (payload) => {
          try {
            if (!payload.email) {
              toast.error('No valid email found');
              return { data: 'No email' };
            }
            const response = await axios({
              url: `${baseURL}/resend/mfa/${payload.organization_uuid}`,
              method: 'POST',
              data: { email: payload.email },
            });

            toast.success('New Code Sent');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Validate Password Reset Token
      validatePasswordResetToken: build.query({
        queryFn: async ({ organization_uuid, token_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${organization_uuid}/${token_uuid}`,
              method: 'GET',
              primaryDB: true, // needs to get newly generated token from primary db
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Reset Password
      resetPassword: build.mutation({
        queryFn: async ({ organization_uuid, data }) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${organization_uuid}`,
              method: 'POST',
              data,
            });
            ls.remove('user');
            toast.success('Password updated. Please log back in.');
            history.push(`/login/${organization_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Password Complexity
      getPasswordComplexity: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/pw-complexity`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Reset Password Private (Logged In)
      resetPasswordPrivate: build.mutation({
        queryFn: async (payload, api) => {
          const storedUserObject = JSON.parse(localStorage.getItem('user'));
          const organizationUUID = storedUserObject.organization_uuid;
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-private`,
              method: 'POST',
              data: payload,
            });
            await api.dispatch(startLogout());
            history.push(`/login/${organizationUUID}`);
            toast.success('Password updated! Please log in again.');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Update Personal Info
      updatePersonalInfo: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/self`,
              method: 'PUT',
              data: payload,
            });
            api.dispatch(updateCurrentUser(payload));
            toast.success('Personal information updated!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get My Sessions
      getSessions: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/lender-sessions/self`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [tags.sessions],
      }),



      // Get Active Sessions for Lender
      getSessionsLender: build.query({
        queryFn: async (lender_uuid) => {
          try {
            const response = await axios({
              url: `${baseURL}/lender-sessions/${lender_uuid}`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Logout By Session
      logoutBySession: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/lender-sessions/self/${payload.session_uuid}`,
              method: 'DELETE',
            });
            ls.remove('user');
            ls.remove('page-has-been-force-refreshed');
            ls.remove('config');
            toast.warning('The selected session have been successfully logged out of the system.');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: invalidateTags([tags.sessions]),
      }),



      // Logout All My Sessions
      logoutAllMySessions: build.mutation({
        queryFn: async (data, api) => {
          const storedUserObject = JSON.parse(localStorage.getItem('user'));
          const organizationUUID = storedUserObject.organization_uuid;
          try {
            const response = await axios({
              url: `${baseURL}/lender-sessions/self/all`,
              method: 'DELETE',
            });
            await api.dispatch(startLogout());
            ls.remove('user');
            ls.remove('page-has-been-force-refreshed');
            ls.remove('config');
            toast.warning('All sessions have been successfully logged out of the system.');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          } finally {
            history.push(`/login/${organizationUUID}`);
          }
        },
        invalidatesTags: invalidateTags([tags.sessions]),
      }),



      // Get Current TOS
      getTOS: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/tos/current`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Accept TOS
      acceptTOS: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/tos-agreement`,
              method: 'POST',
              data: payload,
            });
            api.dispatch(updateCurrentUser({ refresh_tos: false }));
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),


      // Get Organization Branding
      getOrganizationBranding: build.query({
        queryFn: async (organization_uuid) => {
          try {
            const response = await axios({
              url: `${baseURL}/organizations/${organization_uuid}/branding`,
              method: 'GET',
              providesTags: [],
            });

            ls('organizationBranding', {
              organization_uuid,
              ...response.data,
            });

            // Set Theme
            const htmlTag = document.documentElement;
            const currentTheme = htmlTag.getAttribute('data-bs-theme');
            if (currentTheme !== response.data.theme) {
              htmlTag.setAttribute('data-bs-theme', response.data.theme);
            }

            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      // Get Lender Sessions (Self)
      getJournalLoginsSelf: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/lender-sessions/self`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),

    }),
  });



export const {
  useLoginMutation,
  useLogoutMutation,

  useGetRegistrationDataQuery,
  useRegisterMutation,

  useSendForgotPasswordMutation,
  useValidateMFAMutation,
  useResendMFACodeMutation,

  useValidatePasswordResetTokenQuery,
  useResetPasswordMutation,

  useGetPasswordComplexityQuery,
  useResetPasswordPrivateMutation,

  useUpdatePersonalInfoMutation,

  useGetSessionsQuery,
  useGetSessionsLenderQuery,
  useLogoutBySessionMutation,
  useLogoutAllMySessionsMutation,

  useGetTOSQuery,
  useAcceptTOSMutation,

  useGetOrganizationBrandingQuery,
} = authApi;

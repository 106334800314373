import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import TOSModal from '../../components/auth/TOSModal';
import NavBar from '../../components/navigation/NavBar';
import SideNav from '../../components/navigation/SideNav';
import { useToggle } from '../../hooks';

const AppWrapper = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const refresh_tos = useSelector((state) => state.auth.currentUser.refresh_tos);

  const [sideNavOpen, toggleSideNav] = useToggle(true);

  const location = useLocation();

  return (
    <>
      <div className='d-flex flex-row'>
        <SideNav isOpen={sideNavOpen} toggle={toggleSideNav} />

        <div className={`app-wrapper ${sideNavOpen ? 'sidenav-active' : ''}`}>
          <NavBar sideNavOpen={sideNavOpen} toggleSideNav={toggleSideNav} />
          <div className='content-wrapper bg-light'>
            <div className={`content-wrapper-inner position-relative bg-white ${location?.pathname?.includes('dashboard') ? '' : 'p-4 p-sm-6'}`}>
              {children}
            </div>
          </div>
        </div>
      </div>

      {/* Force accept TOS if refresh_tos is true */}
      {isLoggedIn && refresh_tos && <TOSModal />}
    </>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default AppWrapper;

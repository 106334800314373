import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'murder-hornet-ui';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLogoutMutation, useGetOrganizationBrandingQuery } from '../../redux/reducers/auth';
import SideNavSection from './SideNavSection';


const SideNav = ({ isOpen, toggle }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const organization_uuid = useSelector((state) => state.auth.organization_uuid);
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const location = useLocation();
  const navigate = useNavigate();

  const { data: organizationBranding = {} } = useGetOrganizationBrandingQuery(organization_uuid, { skip: !organization_uuid });
  const [logout] = useLogoutMutation();

  const [, primaryPath] = location.pathname.split('/');


  return (
    <nav className={`sidenav ${isOpen ? 'active' : ''}`}>
      <div className='d-flex justify-content-between p-3'>
        <div className='sidenav-logo d-flex justify-content-start justify-content-sm-center'>
          {organizationBranding.logo_url && (
            <img
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  navigate('/');
                }
              }}
              className='pointer'
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              src={organizationBranding.logo_url} title='organization logo'
              onClick={() => {
                navigate('/');
                if (window.innerWidth <= 576) toggle();
              }}
            />
          )}
        </div>

        {/* Mobile SideNav close button */}
        <div className='d-sm-none'>
          <Button ghost onClick={toggle}>
            <span className='fas fa-times' />
          </Button>
        </div>
      </div>

      {organizationBranding.name && <p className='fs-5 fw-bold mb-0 px-3'>{organizationBranding.name}</p>}
      <p className='text-muted px-3'>Lender Portal</p>


      {isLoggedIn && (
        <>
          <Button
            ghost
            block
            className={`bg-light-hover pointer ps-3 pe-3 py-2 mb-3 rounded-0 text-reset fw-normal text-start ${primaryPath === 'dashboard' ? 'bg-primary-subtle fw-semibold' : ''}`}
            onClick={(e) => {
              navigate('/dashboard');
              if (e.currentTarget === document.activeElement) {
                e.currentTarget.blur();
              }
              if (window.innerWidth <= 576) toggle();
            }}
          >
            Dashboard
          </Button>


          <SideNavSection
            title='Pipelines'
            permissions={permissions}
            perform={[
              'lender:application:read-all',
              'lender:application:read-location',
              'lender:application:read-assigned',
              'lender:fs-pipeline:read-all',
            ]}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'Applications',
                onClick: () => navigate('/applications'),
                isActive: primaryPath === 'applications',
                perform: [
                  'lender:application:read-all',
                  'lender:application:read-location',
                  'lender:application:read-assigned',
                ],
              },
              {
                id: 2,
                title: 'Funding Sources & Programs',
                onClick: () => navigate('/funding-sources'),
                isActive: primaryPath === 'funding-sources',
                perform: [
                  'lender:fs-pipeline:read-all',
                ],
              },
            ]}
          />

          <SideNavSection
            title='Reports'
            permissions={permissions}
            perform={[
              'lender:report:read-all-reports',
              'lender:report:read-kpi-charts',
            ]}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'All Reports',
                onClick: () => navigate('/reports'),
                isActive: primaryPath === 'reports',
                perform: [
                  'lender:report:read-all-reports',
                ],
              },
              {
                id: 2,
                title: 'KPI Charts',
                onClick: () => navigate('/charts'),
                isActive: primaryPath === 'charts',
                perform: [
                  'lender:report:read-kpi-charts',
                ],
              },
            ]}
          />

          <SideNavSection
            title='Settings'
            permissions={permissions}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'My Settings',
                onClick: () => navigate('/my-settings'),
                isActive: primaryPath === 'my-settings',
              },
              {
                id: 2,
                title: 'System Settings',
                onClick: () => navigate('/system-settings'),
                isActive: primaryPath === 'system-settings',
                perform: [
                  'lender:lender-settings:read',
                  'lender:location-settings:read',
                  'lender:user-settings:read',
                  'lender:user-settings:write',
                  'lender:communication-templates-settings:read',
                  'lender:activity-log-note-templates-settings:read',
                  'lender:payment-settings:read',
                ],
              },
              {
                id: 3,
                title: 'Help',
                onClick: () => navigate('/help'),
                isActive: primaryPath === 'help',
              },
              {
                id: 4,
                title: 'Logout',
                onClick: logout,
              },
            ]}
          />
        </>
      )}
    </nav>
  );
};


SideNav.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};


export default SideNav;
